<template>
  <v-card outlined color="white" class="mb-4">
    <v-data-table
      :headers="headers"
      :items="environment.volumes"
      hide-default-footer
      :page.sync="page"
      :items-per-page="10"
      @page-count="pageCount = $event"
    >
      <template v-slot:no-data>
        <v-alert color="warning" outlined icon="warning" class="mt-6">
          Nenhum volume configurado!
        </v-alert>
      </template>
      <template v-slot:item.name="{ item }">
        <span style="font-family: monospace;">
          {{ prefix }}
        </span>
        <v-chip label style="font-family: monospace;" class="font-weight-bold mx-0" v-if="!locked.includes(item.name)">
          {{ item.name }}
        </v-chip>
        <v-badge icon="lock" color="error" overlap bordered v-else>
          <v-chip label style="font-family: monospace;" class="font-weight-bold mx-0">
            {{ item.name }}
          </v-chip>
        </v-badge>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip label :color="item.status === 'DRAFT' ? 'info' : (item.status === 'ACTIVE' ? 'success' : 'warning')">
          <v-icon small class="mr-2">{{ item.status === 'DRAFT' ? 'edit' : (item.status === 'ACTIVE' ? 'done' : 'drive_folder_upload') }}</v-icon>
          {{ item.status === 'DRAFT' ? 'Editando' : (item.status === 'ACTIVE' ? 'Ativo' : 'Criando') }}
        </v-chip>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
      />
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    environment: {
      type: Object,
      require: true
    },
    project: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    app: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    env: {
      type: String,
      require: true,
      default: ''
    }
  },
  data: () => ({
    prefix: '',
    page: 1,
    pageCount: 0,
    headers: [
      { text: 'Volume', align: 'start', value: 'name', sortable: false },
      { text: 'Status', value: 'status', sortable: false }
    ],
    locked: ['backup']
  }),
  beforeMount () {
    this.prefix = this.app.repository.replace('/', '_') + '_' + this.env + '_'
  }
}
</script>
