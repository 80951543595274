<template>
  <v-dialog v-model="dialog" width="600px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card :tile="$vuetify.breakpoint.xsOnly" class="d-flex flex-column">
      <v-card-title class="pink white--text px-3">
        <span class="headline">Esconder Projeto</span>
        <v-spacer />
        <v-btn icon color="white" @click="cancel()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-4 pb-0" style="text-align: justify;">
        <p>
          <strong>Atenção!</strong> Esconder o projeto "<strong>{{ project.name }}</strong>" fará
          apenas com que ele não seja mais contabilizado nas estatísticas e gráficos do
          <strong>Painel de Gestão</strong>.
        </p>
        <p>
          Não haverá qualquer alteração para a equipe de desenvolvimento do projeto. Se quiser que
          o projeto seja completamente desativado e fique indisponível à equipe de desenvolvimento,
          utilize a função "<strong>Arquivar</strong>" ao invés desta.
        </p>
      </v-card-text>

      <v-alert type="error" icon="warning" :value="error" transition="scale-transition" class="mx-4">
        {{ message }}
      </v-alert>

      <v-card-actions>
        <v-row justify="center"><v-switch inset v-model="project.hide" label="Esconder projeto." @change="setHide()" :loading="saving" /></v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

import EmbrapaHelper from '@/helpers/embrapa'
import ErrorHelper from '@/helpers/error'

export default {
  mixins: [
    EmbrapaHelper,
    ErrorHelper
  ],
  data: () => ({
    dialog: false,
    user: null,
    project: {
      name: '',
      unix: '',
      hide: false
    },
    saving: false,
    error: false,
    message: ''
  }),
  beforeMount () {
    this.user = this.$localStorage.get('user')

    this.reload()
  },
  mounted () {
    if (this.user.authenticated) {
      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }
    }
  },
  watch: {
    dialogEdit (val) {
      val || this.closeEdit()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  methods: {
    open (project) {
      this.reload()

      this.project.name = project.name
      this.project.unix = project.unix
      this.project.hide = project.hide

      this.dialog = true
    },
    reload () {
      this.saving = false

      this.project.name = ''
      this.project.unix = ''
      this.project.hide = false

      this.aware = false
      this.error = false
      this.message = ''
    },
    cancel () {
      this.reload()

      this.dialog = false
    },
    setHide () {
      this.error = false

      if (!navigator.onLine) {
        this.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error = true

        return
      }

      const err = error => {
        this.project.hide = !this.project.hide

        this.saving = false

        this.message = this.errorMessage(error)

        this.error = true
      }

      this.saving = true

      const api = process.env.VUE_APP_API

      axios.get(api + '/status', { timeout: 12000, headers: this.headers }).then(response => {
        axios.post(api + '/manager/project/hide', { project: this.project.unix, hide: this.project.hide }, { headers: this.headers }).then(response => {
          if (this.project.hide) {
            this.$emit('message', 'Projeto "' + this.project.name + '" ESCONDIDO com sucesso!', 'success')
          } else {
            this.$emit('message', 'O projeto "' + this.project.name + '" NÃO está mais escondido!', 'success')
          }

          this.$emit('refresh')

          this.reload()

          this.dialog = false
        }).catch(err)
      }).catch(err)
    }
  }
}
</script>
