<template>
  <v-app>

    <v-app-bar app clipped-left dark color="primary">
      <v-toolbar-title class="pl-0 pt-2">
        <img src="@/assets/embrapa.io-white.png" style="height: 30px;" alt="Logo do Embrapa I/O">
      </v-toolbar-title>

      <v-chip color="white" label text-color="primary" class="ml-6 hidden-sm-and-down">
        <v-icon start class="mr-2">admin_panel_settings</v-icon>
        Painel de Gestão
      </v-chip>

      <v-progress-linear
        :active="syncing"
        :indeterminate="syncing"
        absolute
        bottom
        color="purple"
      />

      <v-spacer />

      <v-btn icon @click="setInactives()"><v-icon>{{ inactives ? 'check_circle' : 'unpublished' }}</v-icon></v-btn>

      <v-menu bottom center v-if="!$vuetify.breakpoint.smAndDown">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
          <v-badge overlap color="light-green" :content="counter.toString()"><v-icon>style</v-icon></v-badge>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-if="counter < 5" @click.stop.prevent="selectAll()">
            <v-list-item-icon class="mr-2">
              <v-icon>style</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Todos
            </v-list-item-content>
            <v-list-item-action>
              <v-icon small color="success">library_add_check</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-else @click.stop.prevent="selectNone()">
            <v-list-item-icon class="mr-2">
              <v-icon>style</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Nenhum
            </v-list-item-content>
            <v-list-item-action>
              <v-icon small color="grey">filter_none</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
          <v-list-item v-for="e in ecosystems" :key="e.name" @click.stop.prevent="filter(e.name)">
            <v-list-item-icon class="mr-2">
              <v-icon>{{ e.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{ e.short }}
            </v-list-item-content>
            <v-list-item-action>
              <v-icon small :color="isSelected(e.name) ? 'success' : 'grey'">{{ isSelected(e.name) ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-text-field append-icon="search" label="Buscar..." single-line hide-details class="hidden-sm-and-down mx-2" color="white" v-model="search" />

      <v-btn icon @click="refresh()" :loading="syncing"><v-icon>sync</v-icon></v-btn>

      <div class="d-flex align-center ml-2" style="margin-left: auto; width: 320px;" v-if="!$vuetify.breakpoint.smAndDown">
        <user-wrapper @message="message" />
      </div>
    </v-app-bar>

    <v-navigation-drawer permanent expand-on-hover absolute :class="$vuetify.breakpoint.smAndDown ? 'pt-14' : 'pt-16'" color="blue-grey lighten-5">

      <v-list nav dense>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>apps</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Painel de Projetos</v-list-item-title>
        </v-list-item>
        <v-list-item link disabled>
          <v-list-item-icon>
            <v-icon>pie_chart</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Gráficos Analíticos</v-list-item-title>
        </v-list-item>
        <v-list-item link disabled>
          <v-list-item-icon>
            <v-icon>table_chart</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Conectores de Dados</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="wizardManagers()">
          <v-list-item-icon>
            <v-icon>manage_accounts</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Usuários Gestores</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list nav dense>
        <v-list-item link @click="refresh()" :disabled="syncing">
          <v-list-item-icon>
            <v-icon>sync</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sincronizar</v-list-item-title>
        </v-list-item>
        <v-list-item link href="https://embrapa.io" target="_blank">
          <v-list-item-icon>
            <v-icon>help_outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Ajuda</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="$router.push('/about')">
          <v-list-item-icon>
            <v-icon>info_outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sobre</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="pl-14">
      <div class="hidden-md-and-up">
        <user-wrapper @message="message" />
        <v-divider />
      </div>
      <v-container fluid grid-list-lg>
        <v-row no-gutters>
          <v-col cols="12">
            <v-alert color="error" icon="warning" dark v-show="error.active">{{ error.message }}</v-alert>
          </v-col>

          <v-col cols="12" sm="12" class="hidden-md-and-up mt-0 py-0 mb-4">
            <v-text-field append-icon="search" label="Buscar..." single-line hide-details v-model="search" />
          </v-col>

          <v-col cols="12" xs="6" sm="4" md="2" lg="1" xl="1" class="mb-4 px-1" v-for="(r, index) in report" :key="index" v-show="r.show">
            <v-tooltip bottom :color="r.color + ' lighten-5'">
              <template v-slot:activator="{ on, attrs }">
                <v-card outlined v-bind="attrs" v-on="on" dark :color="r.color">
                  <v-card-title>
                    <v-icon x-large class="mr-2">{{ r.icon }}</v-icon>
                    <span class="headline">{{ r.value }}</span>
                  </v-card-title>
                </v-card>
              </template>
              <span :class="r.color + '--text'">{{ r.label }}</span>
            </v-tooltip>
          </v-col>

          <v-col v-if="projects.length === 0" class="hidden-md-and-up">
            <v-img src="@/assets/empty.png" height="284" contain class="my-6" />
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="4" xl="3" v-for="p in filtered" :key="p['_id']">
            <v-card class="mb-4 mx-1">
              <v-card-title primary-title class="pr-2">
                <div class="headline" style="overflow: hidden; white-space: nowrap; max-width: 60%;">{{ p.name }}</div>
                <v-spacer />
                <v-tooltip v-if="p.archive" bottom color="orange lighten-5">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small color="orange" class="mr-2">archive</v-icon>
                  </template>
                  <span class="orange--text">Arquivado</span>
                </v-tooltip>
                <v-icon v-else small color="grey lighten-2" class="mr-2">archive</v-icon>
                <v-tooltip v-if="p.hide" bottom color="pink lighten-5">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small color="pink" class="mr-2">visibility_off</v-icon>
                  </template>
                  <span class="pink--text">Desativado</span>
                </v-tooltip>
                <v-icon v-else small color="grey lighten-2" class="mr-2">visibility_off</v-icon>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="website(p)" :disabled="p.support.web !== 'ACTIVE'">
                      <v-list-item-icon class="mr-2">
                        <v-icon>language</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        Website
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon small :color="p.support.web === 'ACTIVE' ? 'success' : 'error'">{{ p.support.web === 'ACTIVE' ? 'cloud_queue' : 'cloud_off' }}</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="api(p)" :disabled="p.support.api !== 'ACTIVE'">
                      <v-list-item-icon class="mr-2">
                        <v-icon>hub</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        API
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon small :color="p.support.web === 'ACTIVE' ? 'success' : 'error'">{{ p.support.api === 'ACTIVE' ? 'cloud_queue' : 'cloud_off' }}</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider />
                    <v-list-item @click="projectHide(p)" :disabled="!isAdmin">
                      <v-list-item-icon class="mr-2">
                        <v-icon>visibility_off</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        Esconder
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="projectArchive(p)" :disabled="!isAdmin">
                      <v-list-item-icon class="mr-2">
                        <v-icon>archive</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        Arquivar
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-card-subtitle style="height: 40px;" class="pt-1 pr-3">
                <div style="font-family: monospace; float: left; overflow: hidden; white-space: nowrap; max-width: 60%;" class="font-weight-bold info--text">{{ p.unix }}</div>
                <v-spacer />
                <v-tooltip v-for="e in ecosystems" :key="e.name" bottom :color="e.color">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" v-on="on" small dark :color="p.ecosystems[e.name] ? e.color + ' lighten-5' : 'grey lighten-2'" style="float: right;" class="overline ml-1 px-1">
                      <v-icon small :color="p.ecosystems[e.name] ? e.color : 'white'">{{ e.icon }}</v-icon>
                    </v-chip>
                  </template>
                  <span>{{ e.short }}</span>
                </v-tooltip>
              </v-card-subtitle>
              <v-divider />
              <apps-small-wrapper :project="p" :boilerplates="boilerplates" :clusters="clusters" :types="types" @message="message" @refresh="refresh" @highlight="highlight" v-if="p.apps.length > 0" />
              <v-img src="@/assets/empty.png" height="250" contain class="my-6" v-else />
              <v-card-actions class="px-2">
                <v-row wrap class="pb-3" v-if="$vuetify.breakpoint.smAndDown">
                  <v-col cols="12" class="py-1">
                    <v-btn color="info" class="white--text ma-0" style="min-width: 60px;" block large depressed @click="projectTeam(p)">
                      <v-icon dark class="mr-2">
                        people_alt
                      </v-icon> Equipe
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="py-1">
                    <v-btn color="warning" class="white--text ma-0" style="min-width: 60px;" block large depressed :disabled="pannel[p.unix] === null || !('hit' in p.apps[pannel[p.unix]])" @click="analytics(p.apps[pannel[p.unix]])">
                      <v-icon dark class="mr-2">
                        poll
                      </v-icon> Painel
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row wrap class="pb-3" v-else>
                  <v-col cols="6" class="py-1 pr-1">
                    <v-btn color="warning" class="white--text ma-0" style="min-width: 60px;" block large depressed :disabled="pannel[p.unix] === null || !('hit' in p.apps[pannel[p.unix]])" @click="analytics(p.apps[pannel[p.unix]])">
                      <v-icon dark class="mr-2">
                        poll
                      </v-icon> Painel
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="py-1 pl-1">
                    <v-btn color="info" class="white--text ma-0" style="min-width: 60px;" block large depressed @click="projectTeam(p)">
                      <v-icon dark class="mr-2">
                        people_alt
                      </v-icon> Equipe
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" class="px-2" v-if="projects && projects.length > 0 && filtered.length === 0">
            <v-alert :value="true" type="warning">
              Sua busca com o termo "{{ search }}" não retornou resultados!
            </v-alert>
          </v-col>
        </v-row>

        <project-settings-wrapper ref="settings" @close="wizard.settings = false" @refresh="refresh" @message="message" />
        <project-team-wrapper ref="team" @close="wizard.team = false" @refresh="refresh" @message="message" />
        <project-archive-wrapper ref="archive" @close="wizard.archive = false" @refresh="refresh" @message="message" />
        <project-hide-wrapper ref="hide" @close="wizard.hide = false" @refresh="refresh" @message="message" />
        <user-manager-wrapper ref="managers" @close="wizard.managers = false" @refresh="refresh" @message="message" />

        <analytics-dialog ref="analytics" v-on="$listeners" />

        <message-wrapper ref="message" />

      </v-container>
      <v-container fill-height fluid v-if="projects.length === 0" class="hidden-sm-and-down">
        <v-row align="center" justify="center">
          <v-col><v-img src="@/assets/empty.png" height="284" contain /></v-col>
        </v-row>
      </v-container>
    </v-main>

    <beta-wrapper position="left-top" />
  </v-app>
</template>
<script>
import axios from 'axios'

import ErrorHelper from '@/helpers/error'
import EmbrapaHelper from '@/helpers/embrapa'

import BetaWrapper from '@/components/BetaRelease'
import UserWrapper from '@/components/UserDialog'
import MessageWrapper from '@/components/MessageDialog'
import AppsSmallWrapper from '@/components/AppsSmall'
import ProjectSettingsWrapper from '@/components/ProjectSettings'
import ProjectTeamWrapper from '@/components/ProjectTeam'
import ProjectArchiveWrapper from '@/components/ProjectArchive'
import ProjectHideWrapper from '@/components/ProjectHide'
import AnalyticsDialog from '@/components/AppAnalytics'
import UserManagerWrapper from '@/components/UserManager'

export default {
  mixins: [
    ErrorHelper,
    EmbrapaHelper
  ],
  components: {
    BetaWrapper,
    UserWrapper,
    MessageWrapper,
    AppsSmallWrapper,
    ProjectSettingsWrapper,
    ProjectTeamWrapper,
    ProjectArchiveWrapper,
    ProjectHideWrapper,
    AnalyticsDialog,
    UserManagerWrapper
  },
  data: () => ({
    sidebar: false,
    projects: [],
    gitlab: process.env.VUE_APP_GITLAB,
    wizard: {
      project: false,
      app: false,
      settings: false,
      team: false,
      archive: false,
      hide: false,
      managers: false
    },
    syncing: false,
    error: {
      active: false,
      message: ''
    },
    boilerplates: [],
    clusters: {},
    types: [],
    search: '',
    pannel: {},
    ecosystems: [
      { name: 'agriculture', short: 'Agricultura', long: 'Agricultura (e fruticultura)', icon: 'compost', color: 'teal' },
      { name: 'livestock', short: 'Pecuária', long: 'Pecuária (e criação de animais)', icon: 'pets', color: 'red' },
      { name: 'forestry', short: 'Florestas', long: 'Florestas (e silvicultura)', icon: 'forest', color: 'green' },
      { name: 'aquaculture', short: 'Aquicultura', long: 'Aquicultura (e pesca)', icon: 'sailing', color: 'blue' },
      { name: 'industry', short: 'Indústria', long: 'Indústria de processamento', icon: 'factory', color: 'blue-grey' },
      { name: 'corporate', short: 'Institucional', long: 'Institucional', icon: 'business', color: 'purple' }
    ],
    selected: [],
    inactives: false
  }),
  beforeMount () {
    axios.get(process.env.VUE_APP_GITLAB + '/api/v4/projects/' + process.env.VUE_APP_BOILERPLATE + '/repository/files/boilerplates.json/raw?ref=main')
      .then(response => {
        this.boilerplates = response.data
      })
      .catch(err => { console.log(err) })

    axios.get(process.env.VUE_APP_GITLAB + '/api/v4/projects/' + process.env.VUE_APP_BOILERPLATE + '/repository/files/clusters.json/raw?ref=main')
      .then(response => {
        this.clusters = response.data
      })
      .catch(err => { console.log(err) })

    axios.get(process.env.VUE_APP_GITLAB + '/api/v4/projects/' + process.env.VUE_APP_BOILERPLATE + '/repository/files/orchestrators.json/raw?ref=main')
      .then(response => {
        this.types = response.data
      })
      .catch(err => { console.log(err) })
  },
  mounted () {
    if (!this.$localStorage.get('user').authenticated) {
      this.$router.push({ path: '/' })
    } else {
      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }

      this.refresh()
    }
  },
  methods: {
    refresh () {
      this.error.active = false

      if (!navigator.onLine) {
        this.error.message = 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.'

        this.error.active = true

        return
      }

      const err = error => {
        this.syncing = false

        this.error.message = this.errorMessage(error)

        this.error.active = true
      }

      this.syncing = true

      const api = process.env.VUE_APP_API

      const self = this

      console.log('#1 - Start synching...')

      axios.get(api + '/status', { timeout: 12000 }).then(response => {
        console.log('#2 - Status OK! Getting support...')

        axios.get(api + '/support', { headers: this.headers }).then(response => {
          const support = response.data

          console.log('#3 - Support OK! Getting projects...')

          axios.get(api + '/manager/projects', { headers: this.headers }).then(response => {
            const projects = response.data

            projects.sort((a, b) => {
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1

              return 0
            })

            const bugs = []
            const hits = []

            projects.forEach(p => {
              console.log('Getting project "' + p.unix + '"...')

              p.apps.forEach(a => {
                for (const key in a.stages) {
                  if (a.stages[key].deploy) console.log(JSON.stringify(a.stages))
                }
              })

              if (p.unix in support) p.support = support[p.unix]
              else p.support = { api: 'UNEXISTS', web: 'UNEXISTS', twa: 'UNEXISTS', bin: 'UNEXISTS', doc: 'UNEXISTS', art: 'UNEXISTS' }

              self.pannel[p.unix] = p.apps.length > 0 ? 0 : null

              bugs.push(api + '/bug/' + p.unix)
              hits.push(api + '/hit/' + p.unix)

              p.apps.forEach(a => {
                a.more = self.boilerplates.filter(b => b.unix === a.boilerplate)[0]
              })
            })

            self.projects = projects

            setTimeout(() => {
              console.log('#6 - All done!')

              self.syncing = false
            }, 1000)

            console.log('#4 - Projects OK! Getting bugs and hits (async)...')

            if (bugs.length > 0) {
              Promise.all(bugs.map(b => axios.get(b, { headers: self.headers }).then(response => {
                const project = self.projects.filter(p => p.unix === response.data.project)[0]

                for (let i = 0; i < project.apps.length; i++) {
                  const unix = project.apps[i].repository.split('/')[1]

                  if (!response.data.bugs[unix]) continue

                  project.apps[i].bug = response.data.bugs[unix]
                }
              }).catch(err => {
                console.log(err)
              }))).finally(() => {
                console.log('#5.a - Bugs OK!')

                this.$forceUpdate()
              })
            }

            if (hits.length > 0) {
              Promise.all(hits.map(b => axios.get(b, { headers: self.headers }).then(response => {
                const project = self.projects.filter(p => p.unix === response.data.project)[0]

                for (let i = 0; i < project.apps.length; i++) {
                  const unix = project.apps[i].repository.split('/')[1]

                  if (!response.data.sites[unix]) continue

                  project.apps[i].hit = response.data.sites[unix]
                }
              }).catch(err => {
                console.log(err)
              }))).finally(() => {
                console.log('#5.b - Hits OK!')

                this.$forceUpdate()
              })
            }
          }).catch(err)
        }).catch(err)
      }).catch(err)
    },
    message (text, type, time) {
      this.$refs.message.open(text, type, time)
    },
    wizardApp (project) {
      this.$refs.app.open(project, this.boilerplates)
    },
    projectInspect (project) {
      this.$refs.inspect.open(project)
    },
    projectSettings (project) {
      this.$refs.settings.open(project)
    },
    projectTeam (project) {
      this.$refs.team.open(project)
    },
    projectArchive (project) {
      this.$refs.archive.open(project)
    },
    projectHide (project) {
      this.$refs.hide.open(project)
    },
    website (project) {
      window.open('https://' + process.env.VUE_APP_SUPPORT_WEB + '/' + project.unix, '_blank')
    },
    api (project) {
      window.open('https://' + process.env.VUE_APP_SUPPORT_API + '/' + project.unix, '_blank')
    },
    highlight (project, index) {
      this.pannel[project] = index

      this.$forceUpdate()
    },
    analytics (app) {
      this.$refs.analytics.open(app)
    },
    ecosystem (project, ecosystem) {
      const err = error => {
        this.syncing = false

        console.log(error)
      }

      this.syncing = true

      const api = process.env.VUE_APP_API

      const ecosystems = JSON.parse(JSON.stringify(project.ecosystems))

      ecosystems[ecosystem] = !ecosystems[ecosystem]

      axios.put(api + '/ecosystem/' + project.unix, ecosystems, { headers: this.headers }).then(response => {
        project.ecosystems[ecosystem] = !project.ecosystems[ecosystem]

        this.syncing = false
      }).catch(err)
    },
    filter (ecosystem) {
      const index = this.selected.indexOf(ecosystem)

      if (index > -1) this.selected.splice(index, 1)
      else this.selected.push(ecosystem)
    },
    isSelected (ecosystem) {
      return this.selected.indexOf(ecosystem) > -1
    },
    selectAll () {
      for (let i = 0; i < this.ecosystems.length; i++) {
        if (this.selected.indexOf(this.ecosystems[i].name) < 0) this.selected.push(this.ecosystems[i].name)
      }
    },
    selectNone () {
      this.selected = []
    },
    hasEcosystem (project) {
      for (let i = 0; i < this.ecosystems.length; i++) {
        if (project.ecosystems[this.ecosystems[i].name]) return true
      }

      return false
    },
    setInactives () {
      this.inactives = !this.inactives

      this.message('Mostrando apenas projetos ' + (this.inactives ? 'ARQUIVADOS e ESCONDIDOS' : 'ATIVOS') + '!', (this.inactives ? 'warning' : 'success'), 3000)
    },
    wizardManagers () {
      this.$refs.managers.open()
    }
  },
  computed: {
    isAdmin () {
      return this.$localStorage.get('user').role === 'Administrator'
    },
    filtered () {
      const self = this

      const filtered = this.projects.filter(s => {
        return (s.archive || s.hide) === self.inactives
      })

      if ((!this.search || /^\s*$/.test(this.search)) && this.selected.length === 0) return filtered

      return filtered.filter(s => {
        return (s.name.toLowerCase().includes(self.search.toLowerCase()) ||
          s.unix.toLowerCase().includes(self.search.toLowerCase())) &&
          (self.selected.length === 0 || self.selected.filter(e => s.ecosystems[e]).length > 0)
      })
    },
    counter () {
      return this.selected.length
    },
    report () {
      const actives = this.projects.filter(p => {
        return !p.archive && !p.hide
      })

      const report = {
        actives: { value: actives.length, label: 'Projetos Ativos', icon: 'architecture', color: 'green', show: true },
        apps: { value: 0, label: 'Aplicações', icon: 'phone_iphone', color: 'pink', show: true },
        deploys: { value: 0, label: 'Versões Entregues (Deploys)', icon: 'rocket_launch', color: 'cyan', show: true },
        links: { value: 0, label: 'URLs Provisionadas', icon: 'public', color: 'amber', show: false },
        employees: { value: 0, label: 'Empregados', icon: 'badge', color: 'indigo', show: true },
        partners: { value: 0, label: 'Parceiros', icon: 'group', color: 'brown', show: true },
        inactives: { value: this.projects.length - actives.length, label: 'Projetos Arquivados e Escondidos', icon: 'unpublished', color: 'orange', show: true }
      }

      this.ecosystems.forEach(e => { report[e.name] = { value: 0, label: e.short, icon: e.icon, color: e.color, show: true } })

      const users = []

      actives.forEach(p => {
        report.apps.value += p.apps.length

        this.ecosystems.forEach(e => {
          if (p.ecosystems[e.name]) report[e.name].value++
        })

        p.team.forEach(m => {
          if (users.indexOf(m.email) < 0) {
            users.push(m.email)

            if (this.isEmbrapa(m.email)) report.employees.value++
            else report.partners.value++
          }
        })

        p.apps.forEach(a => {
          for (const stage in a.stages) {
            report.deploys.value += a.stages[stage].deploy.length
            report.links.value += a.stages[stage].links.length
          }
        })
      })

      return report
    }
  }
}
</script>
