<template>
  <v-card outlined color="grey lighten-3">
    <v-data-table
      :headers="headers"
      :items="environment.variables"
      hide-default-footer
      :page.sync="page"
      :items-per-page="10"
      @page-count="pageCount = $event"
      class="pb-1"
    >
      <template v-slot:no-data>
        <v-alert color="warning" outlined icon="warning" class="mt-6">
          Nenhuma variável de ambiente nesta aplicação!
        </v-alert>
      </template>
      <template v-slot:item.name="{ item }">
        <span style="font-family: monospace;" class="font-weight-bold">
          {{ item.name }}
        </span>
      </template>
      <template v-slot:item.type="{ item }">
        <v-chip dark :color="types[item.type].color" label small class="overline">
          <v-icon left small>{{ types[item.type].icon }}</v-icon>
          {{ item.type }}
        </v-chip>
      </template>
      <template v-slot:item.value="{ item }">
        <v-text-field :value="item.value" dense readonly hide-details hide-spin-buttons single-line flat filled rounded style="font-family: monospace;" v-show="![ 'EMPTY' ].includes(item.type)" />
      </template>
    </v-data-table>
    <v-card-actions class="pl-3">
      <v-btn dark color="pink" @click="$refs['history'].open (environment, project, app, env)"><v-icon class="mr-2">manage_history</v-icon> Histórico</v-btn>
      <!-- <v-btn dark color="cyan" @click="$refs['inspect'].open (environment, project, app, env)"><v-icon>troubleshoot</v-icon></v-btn> -->
      <v-spacer></v-spacer>
      <v-pagination
        v-model="page"
        :length="pageCount"
      />
    </v-card-actions>
    <inspect-vars-wrapper ref="inspect" />
    <history-vars-wrapper ref="history" />
  </v-card>
</template>
<script>
import UtilHelper from '@/helpers/util'
import ErrorHelper from '@/helpers/error'

import InspectVarsWrapper from '@/components/DialogInspectVars.vue'
import HistoryVarsWrapper from '@/components/DialogHistoryVars.vue'

export default {
  props: {
    environment: {
      type: Object,
      require: true
    },
    project: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    app: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    env: {
      type: String,
      require: true,
      default: ''
    }
  },
  components: {
    InspectVarsWrapper,
    HistoryVarsWrapper
  },
  mixins: [
    UtilHelper,
    ErrorHelper
  ],
  data: () => ({
    loading: false,
    error: {
      active: false,
      message: ''
    },
    page: 1,
    pageCount: 0,
    headers: [
      { text: 'Variável', align: 'start', value: 'name', sortable: false },
      { text: 'Tipo', value: 'type', sortable: false },
      { text: 'Valor', value: 'value', sortable: false }
    ],
    volumes: [],
    types: {
      SERVER: { color: 'brown lighten-3', icon: 'dns' },
      SECRET: { color: 'red lighten-3', icon: 'vpn_key' },
      TEXT: { color: 'indigo lighten-3', icon: 'article' },
      PORT: { color: 'teal lighten-3', icon: 'meeting_room' },
      PASSWORD: { color: 'purple lighten-3', icon: 'lock' },
      VOLUME: { color: 'blue-grey lighten-3', icon: 'inventory' },
      EMPTY: { color: 'amber', icon: 'comments_disabled' }
    }
  }),
  methods: {
    loadValue () {
      switch (this.editedItem.type) {
        case 'PASSWORD':
          this.editedItem.value = this.randomString(16)
          break

        case 'SECRET':
          this.editedItem.value = this.randomString(256)
          break

        case 'TEXT':
        case 'EMPTY':
          this.editedItem.value = ''
          break

        case 'PORT':
          this.editedItem.value = ''

          this.loadPort()

          break
      }
    },
    hasType () {
      return ['PASSWORD', 'SECRET', 'TEXT', 'PORT', 'VOLUME', 'EMPTY'].includes(this.editedItem.type)
    },
    hasValue () {
      switch (this.editedItem.type) {
        case 'PASSWORD':
        case 'SECRET':
        case 'TEXT':
          return !/\s/.test(this.editedItem.value)

        case 'PORT':
          return /^\d+$/.test(this.editedItem.value)

        case 'VOLUME':
          return this.volumes.filter(i => i.name === this.editedItem.value).length === 1

        case 'EMPTY':
          return true
      }

      return false
    }
  }
}
</script>
