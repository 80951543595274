<template>
  <v-card :width="width" flat tile color="grey lighten-5">
    <v-card-title>
      <v-avatar tile class="mr-3">
        <font-awesome-icon color="grey" :icon="app.more ? app.more.icon : 'fas fa-code-branch'" />
      </v-avatar>
      <v-tooltip :color="status.color + ' lighten-5'" right>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-badge inline dot :color="status.type">
              <div style="font-family: monospace; overflow: hidden; white-space: nowrap; max-width: 250px;" class="font-weight-bold pt-1">{{ app.repository.split('/')[1] }}</div>
            </v-badge>
          </div>
        </template>
        <span :class="status.color + '--text'">{{ status.text }}</span>
      </v-tooltip>
      <v-spacer />
      <v-icon v-if="!app.bug">hourglass_empty</v-icon>
      <v-tooltip v-else left :color="app.bug.errors > 10 ? 'error' : (app.bug.errors > 0 ? 'warning' : 'success')">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-badge
              overline
              left
              inline
              :color="app.bug.errors > 10 ? 'error' : (app.bug.errors > 0 ? 'warning' : 'success')"
              :content="app.bug.errors.toString()">
              <v-btn large icon @click="bug()">
                <v-icon>bug_report</v-icon>
              </v-btn>
            </v-badge>
          </div>
        </template>
        <span>{{ app.bug.errors }} erro(s) em {{ app.bug.issues }} issue(s)</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text style="background-color: white;" v-if="[ 'CREATING', 'ACTIVATING', 'DEACTIVATING' ].includes(app.status)">
      <v-img src="@/assets/wait.png" height="150" contain />
    </v-card-text>
    <v-card-text class="text-center py-6" style="background-color: white;" v-if="[ 'DEACTIVATED', 'UNDEFINED' ].includes(app.status)">
      <v-icon size="48px" color="error lighten-3" class="my-8">dangerous</v-icon>
    </v-card-text>
    <!--
    status de stages:
    DRAFT
    VALIDATING
    INVALID
    VALID
    DEPLOYING
    ACTIVE
    UNDEPLOYING
    INACTIVATED
    -->
    <v-list dense v-if="app.status === 'ACTIVE'">
      <v-list-item v-for="(b, index) in ['release', 'beta', 'alpha']" :key="index" class="pl-2">
        <v-tooltip top attach>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar v-bind="attrs" v-on="on" :color="stageStatus[app.stages[b].status].color + ' lighten-5'" size="28" rounded class="ml-1 mr-2"><v-icon small :color="stageStatus[app.stages[b].status].color">{{ stageStatus[app.stages[b].status].icon }}</v-icon></v-avatar>
          </template>
          <span>{{ stageStatus[app.stages[b].status].label }}</span>
        </v-tooltip>
        <v-list-item-content>
          <v-list-item-title class="hidden-sm-and-down overline py-2">{{ b }}</v-list-item-title>
          <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
          <v-list-item-title class="hidden-md-and-up py-2" style="font-size: 18px;" v-html="stages[b].greek"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="Array.isArray(app.stages[b].deploy) && app.stages[b].deploy.length > 0">
          <v-list-item-action-text>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" dark label small :color="stages[b].color" class="text-uppercase font-weight-medium" close close-icon="keyboard_arrow_down">
                  <v-icon class="mr-2" small>rocket_launch</v-icon>
                  {{ app.stages[b].deploy[0] }}
                </v-chip>
              </template>
              <v-list dense>
                <v-list-item v-for="(version, index) in app.stages[b].deploy" :key="index">
                  <v-list-item-title>{{ version }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action-text>
        </v-list-item-action>
        <v-list-item-icon>
          <v-btn icon @click="inspect(b)" :disabled="app.stages[b].deploy.length === 0 || !isAdmin || [ 'VALIDATING', 'DEPLOYING', 'UNDEPLOYING' ].includes(app.stages[b].status)"><v-icon>settings</v-icon></v-btn>
          <v-btn icon @click="health(b)" :disabled="app.stages[b].deploy.length === 0 || [ 'UNDEPLOYING', 'INACTIVATED' ].includes(app.stages[b].status)"><v-icon>monitor_heart</v-icon></v-btn>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon :disabled="app.stages[b].links.length === 0 || app.stages[b].status === 'INACTIVATED'" v-bind="attrs" v-on="on"><v-icon>link</v-icon></v-btn>
            </template>
            <v-list max-width="450px">
              <div v-for="(link, sindex) in app.stages[b].links" :key="link.port">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title><a :href="'http://' + link.permanent" target="_blank" style="text-decoration: none;">{{ link.permanent }}</a></v-list-item-title>
                    <v-list-item-subtitle v-if="link.subdomain && checkUrl(link.subdomain)">
                      <i>ou</i> <a :href="link.subdomain" target="_blank" style="text-decoration: none;">{{ link.subdomain }}</a>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="link.alias && checkUrl(link.alias)">
                      <i>ou</i> <a :href="link.alias" target="_blank" style="text-decoration: none;">{{ link.alias }}</a>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="link.subpath && checkUrl(link.subpath)">
                      <i>ou</i> <a :href="link.subpath" target="_blank" style="text-decoration: none;">{{ link.subpath }}</a>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon><v-icon color="success" x-large>public</v-icon></v-list-item-icon>
                </v-list-item>
                <v-divider v-if="sindex < app.stages[b].links.length - 1" :key="sindex" />
              </div>
            </v-list>
          </v-menu>
        </v-list-item-icon>
      </v-list-item>
    </v-list>

    <v-dialog v-model="wizard" max-width="800px" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
      <wizard-environment-wrapper @close="wizard = false" v-on="$listeners" :project="project" :app="app" :env="environment" :boilerplates="boilerplates" :clusters="clusters" :types="types" />
    </v-dialog>

    <build-inspect-dialog ref="inspect" v-on="$listeners" :boilerplates="boilerplates" :clusters="clusters" :types="types" />
    <build-health-dialog ref="health" v-on="$listeners" />

    <bug-dialog ref="bug" v-on="$listeners" />
  </v-card>
</template>

<script>
import WizardEnvironmentWrapper from '@/components/WizardEnvironment'
import BuildInspectDialog from '@/components/BuildInspect'
import BuildHealthDialog from '@/components/BuildHealth'
import BugDialog from '@/components/AppBug'

import UtilHelper from '@/helpers/util.js'

export default {
  mixins: [
    UtilHelper
  ],
  props: {
    project: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    app: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    width: {
      type: String,
      default: undefined
    },
    boilerplates: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    },
    clusters: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    types: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    }
  },
  components: {
    WizardEnvironmentWrapper,
    BuildHealthDialog,
    BuildInspectDialog,
    BugDialog
  },
  data: () => ({
    wizard: false,
    environment: null,
    status: {
      icon: 'hourglass_empty',
      type: 'warning',
      text: 'Carregando...'
    },
    stageStatus: {
      DRAFT: { icon: 'edit', color: 'blue', label: 'Rascunho' },
      VALIDATING: { icon: 'rule', color: 'orange', label: 'Validando' },
      INVALID: { icon: 'warning', color: 'red', label: 'Inválido' },
      VALID: { icon: 'verified', color: 'teal', label: 'Válido' },
      DEPLOYING: { icon: 'cloud_upload', color: 'purple', label: 'Instanciando' },
      ACTIVE: { icon: 'cloud_done', color: 'green', label: 'Ativo' },
      UNDEPLOYING: { icon: 'cloud_download', color: 'deep-orange', label: 'Desinstanciando' },
      INACTIVATED: { icon: 'public_off', color: 'blue-grey', label: 'Inativo' }
    },
    stages: {
      alpha: { greek: '&alpha;', color: 'cyan darken-1' },
      beta: { greek: '&beta;', color: 'cyan darken-2' },
      release: { greek: '&rho;', color: 'cyan darken-3' }
    }
  }),
  beforeMount () {
    this.type()
  },
  watch: {
    app: {
      handler () {
        this.type()
      },
      deep: true
    }
  },
  methods: {
    type () {
      switch (this.app.status) {
        case 'ACTIVE':
          this.status.icon = 'done'
          this.status.type = 'success'
          this.status.text = 'Ativo!'
          this.status.color = 'green'

          break

        case 'DEACTIVATED':
          this.status.icon = 'close'
          this.status.type = 'error'
          this.status.text = 'App desativado!'
          this.status.color = 'red'

          break

        case 'CREATING':
          this.status.icon = 'construction'
          this.status.type = 'warning'
          this.status.text = 'Criando... por favor, aguarde!'
          this.status.color = 'orange'

          break

        case 'ACTIVATING':
          this.status.icon = 'file_upload'
          this.status.type = 'warning'
          this.status.text = 'Ativando... por favor, aguarde!'
          this.status.color = 'orange'

          break

        case 'DEACTIVATING':
          this.status.icon = 'file_download'
          this.status.type = 'error'
          this.status.text = 'Desativando... por favor, aguarde!'
          this.status.color = 'red'

          break

        case 'UNDEFINED':
        default:
          this.status.icon = 'error'
          this.status.type = 'error'
          this.status.text = 'Erro! Impossível obter o status.'
          this.status.color = 'red'
      }
    },
    settings (env) {
      this.environment = env

      this.wizard = true
    },
    health (env) {
      this.$refs.health.open(this.project, this.app, env)
    },
    inspect (env) {
      this.$refs.inspect.open(this.project, this.app, env)
    },
    bug () {
      this.$refs.bug.open(this.app)
    }
  },
  computed: {
    isAdmin () {
      return this.$localStorage.get('user').role === 'Administrator'
    }
  }
}
</script>
