<template>
  <v-dialog v-model="dialog" width="800px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card :tile="$vuetify.breakpoint.xsOnly" class="d-flex flex-column">
      <v-card-title class="brown white--text px-3">
        <span class="headline">Gestores</span>
        <v-spacer />
        <v-btn color="info" @click="copy()" class="mr-2">
          <v-icon class="mr-2">copy_all</v-icon>
          Copiar
        </v-btn>
        <v-btn color="warning" @click="mail()" class="mr-2">
          <v-icon class="mr-2">mail</v-icon>
          e-Mail
        </v-btn>
        <v-btn color="success" @click="dialogEdit = true" :disabled="!isAdmin">
          <v-icon class="mr-2">person_add</v-icon>
          Adicionar
        </v-btn>
        <v-btn icon class="hidden-sm-and-up ml-2" color="white" @click="cancel()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="columns"
        :items="team"
        disable-pagination
        fixed-header
        hide-default-footer
        class="elevation-0"
        light
      >
        <template v-slot:item.role="props">
          <v-edit-dialog
            v-if="isAdmin && props.item.email !== user.email"
            :return-value.sync="props.item.role"
            large
            save-text="Alterar"
            cancel-text="Cancelar"
            @save="change(props.item)"
          >
            <v-chip
              :color="props.item.role === 'Administrator' ? 'error' : 'info'"
              label
              outlined
              style="cursor: pointer;">
              <v-icon small :color="props.item.role === 'Administrator' ? 'error' : 'info'" class="mr-2">{{ props.item.role === 'Administrator' ? 'local_police' : 'policy' }}</v-icon>
              {{ props.item.role === 'Administrator' ? 'Administrador' : 'Analista de Dados' }}
            </v-chip>
            <template v-slot:input>
              <v-radio-group v-model="props.item.role" mandatory>
                <v-radio value="Analyst" label="Analista de Dados" />
                <v-radio value="Administrator" label="Administrador" :disabled="!isEmbrapa(props.item.email)" />
              </v-radio-group>
            </template>
          </v-edit-dialog>
          <v-chip
            v-else
            :color="props.item.role === 'Administrator' ? 'error' : 'info'"
            label
            outlined>
            <v-icon small :color="props.item.role === 'Administrator' ? 'error' : 'info'" class="mr-2">{{ props.item.role === 'Administrator' ? 'local_police' : 'policy' }}</v-icon>
            {{ props.item.role === 'Administrator' ? 'Administrador' : 'Analista de Dados' }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            @click="remove(item)"
            :disabled="!isAdmin || item.email === user.email"
            color="error"
          >
            remove_circle_outline
          </v-icon>
        </template>
      </v-data-table>

      <v-alert type="error" icon="warning" :value="error" transition="scale-transition" class="mx-4">
        {{ message }}
      </v-alert>
    </v-card>

    <v-dialog v-model="dialogEdit" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Adicionar Membro à Equipe</span>
        </v-card-title>

        <v-card-text class="pb-0">
          <v-container>
            <v-form v-model="validate">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="manager.email"
                    :items="users"
                    :loading="loading"
                    :search-input.sync="search"
                    hide-no-data
                    hide-selected
                    item-text="email"
                    item-value="email"
                    label="Usuário"
                    placeholder="Procurar..."
                    prepend-inner-icon="person_search"
                    outlined
                    :rules="rules"
                  />
                </v-col>
                <v-col cols="12">
                  <v-radio-group v-model="manager.role" mandatory class="my-0 py-0" :rules="[ v => manager.role !== 'None' ]">
                    <v-radio value="None" label="Sem Credencial de Acesso" />
                    <v-radio value="Analyst" label="Analista de Dados" />
                    <v-radio value="Administrator" label="Administrador" :disabled="!isEmbrapa(manager.email)" />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            @click="role()"
            :disabled="!validate"
          >
            <v-icon class="mr-2">done</v-icon>
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Remover usuário da equipe de gestores?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" text @click="close()">Não</v-btn>
          <v-btn color="error" @click="role()">Sim</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import axios from 'axios'

import EmbrapaHelper from '@/helpers/embrapa'
import ErrorHelper from '@/helpers/error'

export default {
  mixins: [
    EmbrapaHelper,
    ErrorHelper
  ],
  data: () => ({
    dialog: false,
    user: null,
    team: [],
    dialogEdit: false,
    dialogDelete: false,
    columns: [
      { text: 'Nome', align: 'start', value: 'name' },
      { text: 'e-Mail', value: 'email' },
      { text: 'Credencial', value: 'role' },
      { text: '', value: 'actions', sortable: false }
    ],
    saving: false,
    headers: {},
    error: false,
    message: '',
    manager: {
      email: '',
      role: 'None'
    },
    rules: [
      v => !!v || 'Não pode ser vazio!',
      v => !v || /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(v) || 'O e-mail precisa ser válido!'
    ],
    validate: false,
    users: [],
    loading: false,
    search: null
  }),
  beforeMount () {
    this.user = this.$localStorage.get('user')

    this.headers = {
      Authorization: 'Bearer ' + this.user.token
    }

    this.reload()
  },
  mounted () {
    if (this.user.authenticated) {
      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }
    }
  },
  watch: {
    search (val) {
      if (this.loading) return

      this.loading = true

      axios.get(process.env.VUE_APP_API + '/manager/users', { headers: this.headers }).then(response => {
        this.users = response.data
      }).catch(err => console.log(err)).finally(() => (this.loading = false))
    }
  },
  methods: {
    open () {
      this.reload()

      if (!navigator.onLine) {
        this.$emit('message', 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.', 'error')

        return
      }

      this.refresh()
    },
    refresh () {
      const err = error => {
        this.$emit('message', this.errorMessage(error), 'error')
      }

      const api = process.env.VUE_APP_API

      axios.get(api + '/status', { timeout: 12000 }).then(response => {
        axios.get(api + '/manager/managers', { headers: this.headers }).then(response => {
          this.team = response.data

          this.dialog = true
        }).catch(err)
      }).catch(err)
    },
    reload () {
      this.saving = false

      this.team = []

      this.error = false
      this.message = ''
    },
    cancel () {
      this.reload()

      this.dialog = false
    },
    close () {
      this.dialogEdit = false
      this.dialogDelete = false

      this.manager.email = ''
      this.manager.role = 'None'
    },
    remove (item) {
      this.manager.email = item.email
      this.manager.role = 'None'

      this.dialogDelete = true
    },
    change (item) {
      this.manager.email = item.email
      this.manager.role = item.role

      this.role()
    },
    role () {
      axios.post(process.env.VUE_APP_API + '/manager/role', this.manager, { headers: this.headers }).then(response => {
        this.refresh()

        this.manager.email = ''
        this.manager.role = 'None'

        this.dialogEdit = false
        this.dialogDelete = false

        this.$emit('message', 'Credenciais do usuário alteradas com sucesso!', 'success')
      }).catch(error => {
        this.$emit('message', error, 'error')
      })
    },
    copy () {
      const emails = this.team.map(m => m.email).join(', ')

      navigator.clipboard.writeText(emails).then(() => {
        this.$emit('message', 'Lista de e-mails copiada para a área de transferência!', 'success')
      }).catch(() => {
        this.$emit('message', 'Não foi possível copiar a lista de e-mails para a área de transferência!', 'error')
      })
    },
    mail () {
      const emails = this.team.filter(m => m.email !== this.user.email).map(m => m.email).join(';')

      window.open('mailto:' + emails + '?subject=' + encodeURIComponent('Embrapa I/O: Painel de Gestão'))
    }
  },
  computed: {
    isAdmin () {
      return this.$localStorage.get('user').role === 'Administrator'
    }
  }
}
</script>
