export default {
  methods: {
    isEmbrapa (email) {
      if (!email || email.trim() === '' || !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(email)) return false

      const address = email.split('@').pop()

      return require('tld-extract').parse_host(address).domain === 'embrapa.br'
    }
  }
}
