<template>
  <v-dialog v-model="dialog" width="600px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card :tile="$vuetify.breakpoint.xsOnly" class="d-flex flex-column">
      <v-toolbar color="success" dark>
        <v-toolbar-title>{{ 'Ambiente de ' + environments[env].title }}</v-toolbar-title>
        <v-spacer />
        <v-chip label style="font-family: monospace; font-weight: bold; word-spacing: -5px;" color="rgb(255,255,255,0.3)" class="white--text">{{ app.repository.split('/').join(' / ') }} @ {{ env }}</v-chip>

        <template v-slot:extension>
          <v-tabs v-model="tab" centered>
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab>
              <v-icon class="mr-2">dns</v-icon>
              Cluster
            </v-tab>
            <v-tab>
              <v-icon class="mr-2">folder_copy</v-icon>
              Volumes
            </v-tab>
            <v-tab>
              <v-icon class="mr-2">tune</v-icon>
              Variáveis
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="tab">

        <!-- Cluster -->
        <v-tab-item>
          <v-card shaped color="blue-grey darken-2" class="ma-4" dark v-if="choosedCluster" transition-group="fade-transition">
            <v-card-title class="overline pb-0">{{ choosedCluster.host }}</v-card-title>
            <v-card-text>
              Cluster na {{ choosedCluster.local }}, em {{ choosedCluster.location }}, com orquestrador '{{ choosedCluster.orchestrator }}' e
              storer '{{ choosedCluster.storage.type }}'.
            </v-card-text>
            <v-list color="blue-grey darken-1">
              <v-subheader>Aliases</v-subheader>
              <div class="px-3">
                <v-chip v-for="(alias, index) in choosedCluster.aliases" :key="index" label outlined dark class="mx-1 mb-2">{{ alias }}</v-chip>
              </div>
            </v-list>
            <v-list color="blue-grey">
              <v-subheader>Mantenedores</v-subheader>
              <v-list-item v-for="(user, index) in choosedCluster.maintainers" :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ user.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.email }} &bull; {{ user.phone }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-btn icon :href="'https://api.whatsapp.com/send?phone=' + user.phone.replace(/\D/g,'') + '&text=' + encodeURIComponent('Embrapa I/O: Preciso de informações sobre o cluster ' + choosedCluster.host + '!')" target="_blank"><v-icon>phone</v-icon></v-btn>
                  <v-btn icon :href="'mailto:' + user.email" target="_blank"><v-icon>mail</v-icon></v-btn>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card>
        </v-tab-item>

        <!-- Volumes -->
        <v-tab-item>
          <build-inspect-volumes :environment="environment" :app="app" :project="project" :env="env" />
        </v-tab-item>

        <!-- Env Vars -->
        <v-tab-item>
          <build-inspect-vars :environment="environment" :app="app" :project="project" :env="env" />
        </v-tab-item>
      </v-tabs-items>
      <v-alert color="error" icon="warning" dark v-show="error.active" class="mx-3">{{ error.message }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import md5 from 'crypto-js/md5'

import ErrorHelper from '@/helpers/error'
import UtilHelper from '@/helpers/util'

import BuildInspectVolumes from '@/components/BuildInspectVolumes'
import BuildInspectVars from '@/components/BuildInspectVars'

export default {
  mixins: [
    ErrorHelper,
    UtilHelper
  ],
  components: {
    BuildInspectVolumes,
    BuildInspectVars
  },
  props: {
    boilerplates: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    },
    clusters: {
      type: Object,
      require: true,
      default: () => {
        return {}
      }
    },
    types: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    }
  },
  data: () => ({
    dialog: false,
    tab: null,
    project: null,
    app: null,
    env: null,
    accept: false,
    step: 1,
    loading: false,
    saving: false,
    headers: {},
    domains: [],
    subdomains: [],
    environments: {
      alpha: {
        title: 'Testes Internos'
      },
      beta: {
        title: 'Testes Externos'
      },
      release: {
        title: 'Produção'
      }
    },
    environment: {
      status: 'DRAFT',
      cluster: '',
      version: 0,
      variables: [],
      volumes: [],
      urls: [],
      aliases: [],
      deploy: null
    },
    error: {
      active: false,
      message: ''
    },
    deploy: false,
    choosedCluster: null
  }),
  methods: {
    open (project, app, stage) {
      this.project = project
      this.app = app
      this.env = stage

      if (!navigator.onLine) {
        this.$emit('message', 'É necessário uma conexão com a internet para prosseguir! Por favor, verifique suas configurações de rede ou tente novamente mais tarde.', 'error')

        return
      }

      const err = error => {
        this.$emit('message', this.errorMessage(error), 'error')
      }

      this.headers = {
        Authorization: 'Bearer ' + this.$localStorage.get('user').token
      }

      const api = process.env.VUE_APP_API

      axios.get(api + '/status', { timeout: 12000 }).then(response => {
        axios.get(api + '/build/' + this.app.repository + '/' + this.env, { headers: this.headers }).then(response => {
          this.environment = response.data

          this.domains = this.clusters[this.env]

          if (this.checkDomain(this.environment.cluster)) this.subdomains = this.domains.filter(i => i.host === this.environment.cluster)[0].aliases

          this.initializeVariables()

          this.loading = false

          this.dialog = true
        }).catch(err)
      }).catch(err)
    },
    reset () {
      this.tab = 1
      this.loading = false
      this.saving = false
      this.deploy = false

      this.domains = []
      this.subdomains = []

      this.choosedCluster = null

      this.environment = {
        status: 'DRAFT',
        cluster: '',
        version: 0,
        variables: [],
        volumes: [],
        urls: [],
        aliases: [],
        deploy: null
      }

      this.error.active = false
      this.error.message = ''
    },
    cancel () {
      this.reset()

      this.$emit('close')
    },
    initializeVariables () {
      if (!this.checkDomain(this.environment.cluster)) return

      const aux = this.app.repository.split('/')

      const unixP = aux[0]
      const unixA = aux[1]

      const date = new Date()

      const user = this.$localStorage.get('user')

      this.choosedCluster = this.clusters[this.env].filter(c => c.host === this.environment.cluster)[0]

      const replace = {
        '%SERVER%': this.environment.cluster,
        '%STAGE%': this.env,
        '%PROJECT_UNIX%': unixP,
        '%APP_UNIX%': unixA,
        '%VERSION%': '2.' + date.getFullYear().toString().substring(2) + '.' + (date.getMonth() + 1).toString() + '-' + (this.env !== 'release' ? this.env + '.' : '') + '7',
        '%DEPLOYER%': user.email,
        '%SENTRY_DSN%': this.app.bug ? 'https://' + this.app.bug.key + '@' + process.env.VUE_APP_SENTRY_HOST + '/' + this.app.bug.id : 'ERROR_TO_LOAD_SENTRY_DSN',
        '%MATOMO_ID%': this.app.hit ? this.app.hit.id : 'ERROR_TO_LOAD_MATOMO_ID',
        '%MATOMO_TOKEN%': md5(this.app.repository + '@' + this.env).toString()
      }

      const s = JSON.parse(JSON.stringify(this.types.filter(i => i.type === this.choosedCluster.orchestrator)[0].variables))

      const server = []

      for (const key in s) {
        for (const tag in replace) {
          s[key] = s[key].replace(tag, replace[tag])
        }

        server.push({ name: key, type: 'SERVER', value: s[key] })
      }

      this.environment.variables = server.concat(this.environment.variables.filter(i => i.type !== 'SERVER'))
    }
  }
}
</script>
